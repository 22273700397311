import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import useSound from 'use-sound';

import clickSfx from '../../../app/assets/sounds/Bank Card.wav';
import GridRow from '../../../components/layout/GridRow';
import Col from '../../../components/layout/Col';

export const Layout = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    &::after {
      background: var(--primary);
      opacity: 0.55;
      transition: all 0.5s ease-in-out;
      transition-delay: 2.5s;
      cursor: pointer !important;
    }

    * {
      color: var(--primary2);
      transition: all 0.5s ease-in-out;
      transition-delay: 2.5s;
    }

    transition: all 0.5s ease-in-out;
    transition-delay: 2.5s;
  }

  *:not(button) {
    color: var(--primary);
  }

  // Create the overlay effect using ::before and ::after
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90%;
    width: 96%;

    border-radius: 2rem;
    margin: auto;

    // Ensure at least one overlay is visible
    // Adjust opacities if needed
    opacity: 0.8; // For testing, set it to a visible value
    transition: all 0.5s ease-in-out;
    // Adjust z-index to ensure overlays appear correctly
    z-index: 1;

    opacity: 0.75;
    transition: all 0.5s ease-in-out;
    transition-delay: 2.5s;
    background: var(--primary2);
  }
  &::before {
    background: ${({ mainImage }) =>
      mainImage
        ? `url(${mainImage}) no-repeat center center/cover`
        : 'gray'}; // Add fallback color for testing
    opacity: 1; // Ensure the main overlay is visible
    z-index: 0; // Consider increasing this if the overlay needs to be at the bottom
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
`;

export const Wrapper = styled.div`
  height: 90%;
  width: 96%;
  margin: auto;
  padding: 4rem;
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const SvgWrapper = styled(motion.div)`
  /* height: 50%; */
  width: 100%;
  margin: auto;
  padding: 4rem;
  padding-left: 0;
  background: white;
  background: transparent;
  border-radius: 1.2rem;
  /* clip-path: url(#myClip); */
  /* clip-path: polygon(0 0, 35% 0, 14% 100%, 0% 100%);
  transition: clip-path 0.35s ease-in-out;

  &:hover {
    clip-path: polygon(0 0, 105% 0, 14% 100%, 0% 100%);
    transition: clip-path 0.5s ease-in-out;
  } */

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0 0;
  padding: 0;
  z-index: 2;
  width: fit-content;
  text-align: left;

  /* @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    text-align: center;
    margin: 0 auto;
  } */
`;

export const ListItem = styled.li`
  font-size: 1.38rem;
  margin: 0.6rem 0;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: -0.01rem;
  line-height: 1.5;
  font-weight: 600;

  text-align: left;

  @media screen and (max-width: 768px) {
    margin: 0.2rem 0;
    font-size: 1.24rem;
    font-weight: 500;
  }
`;

const Container = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0.2rem 0;
    /* font-size: 1.9rem; */
    /* margin-right: auto; */
  }
`;

export const Title = styled.h2`
  font-size: 3.4rem;
  font-family: 'Instrument Sans';
  line-height: 1.25;
  margin: 1rem 0;
  font-weight: 500;
  z-index: 2;
  color: var(--primary);

  @media screen and (max-width: 768px) {
    font-size: 3.45rem;
    text-align: center;
  }
`;

export const Title2 = styled.h2`
  font-size: 4rem;
  /* font-family: 'Whyte Ink Trap'; */
  line-height: 1.25;
  margin: 0;
  color: var(--primary);

  font-weight: 100;
  font-family: 'Abu Dhabi';
  font-size: 5rem;
  /* line-height: 0.15; */
  letter-spacing: 0.025rem;
  z-index: 2;

  @media screen and (max-width: 768px) {
    font-size: 3.95rem;
    text-align: center;
    font-style: italic;
    font-family: 'Abu Dhabi', Helvetica, Arial, sans-serif;
    font-weight: 400;
  }
`;

export const Label = styled.h3`
  position: relative;
  font-size: 1.35rem;
  text-transform: uppercase;
  font-weight: 500;
  /* letter-spacing: 0.1rem;
  text-decoration: underline;
  text-underline-offset: 0.5rem; */
  /* background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%); */
  background: var(--primary);

  opacity: 0.7;
  padding: 0.43rem 0.6rem;
  border-radius: 0.5rem;
  width: fit-content;
  z-index: 2;
  color: var(--primary2) !important;
  letter-spacing: 0.05rem;

  // these are labels for categories, can you nth-child to change the color of the label to make nice modern gradient colors
  /* &:nth-child(1) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  }
  &:nth-child(2) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  }
  &:nth-child(3) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  }
  &:nth-child(4) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  }
  &:nth-child(5) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  }
  &:nth-child(6) {
    background: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
  } */

  /* background-color: rgba(255, 255, 255, 0.4); */
  /* border: 0.4px solid var(--primary2); */

  /* &:after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    width: 100%;
    border-bottom: 2px solid black;
  } */

  @media screen and (max-width: 768px) {
    /* margin-right: auto; */
  }
`;

export const Button = styled(Link)`
  width: fit-content;
  font-size: 1.33rem;
  border: none;

  border: 1.2px solid var(--primary2);
  text-decoration: none;
  border-radius: 1rem;
  width: 20rem;
  height: 3.05rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  border: 2px solid rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0px 2px 5px rgba(255, 255, 255, 0.7));
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  color: #6e808c;
  color: var(--primary2);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.3);
  opacity: 1;

  transition: all 0.2s ease-in-out;

  > i {
    color: var(--primary) !important;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);

    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.7);
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
`;

const OrientationPrompt = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
`;

export const PortfolioItemOverview = ({ data }) => {
  const {
    services,
    title,
    title2,
    label,
    internalLink,
    mainImage,
    images,
    mobileImages,
    logo,
    slug,
  } = data;
  console.log(data);

  const [showModal, setShowModal] = useState(false);

  const [orientation, setOrientation] = useState(window.orientation);

  const [playAppear] = useSound(clickSfx, { volume: 0.005 });
  const [playClick] = useSound(clickSfx, { volume: 0.025 });

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.orientation);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <Layout mainImage={mainImage}>
      {/* {showModal && (
        <FullscreenModal onClick={() => setShowModal(false)}>
          <ModalImage src={mainImage} alt='Showcase' />
        </FullscreenModal>
      )} */}

      {/* //mobile */}
      {/* {showModal && orientation !== 90 && <OrientationPrompt>Please tilt your screen for a better view.</OrientationPrompt>} */}

      <Wrapper>
        <GridRow col={2} colTemplate="2fr 6fr" height="70vh" mobile>
          <SvgWrapper
            initial={{
              opacity: 0,
              y: -10,
              clipPath: 'polygon(0 0, 35% 0, 14% 100%, 0% 100%)',
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              clipPath: ' polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              transition: {
                duration: 1,
                delay: 0.47,
              },
            }}
          >
            <List>
              {services.map((service) => (
                <ListItem>{service}</ListItem>
              ))}
            </List>
          </SvgWrapper>

          <Col justify="space-between">
            <Label>{label}</Label>
            <Container>
              <Title>{title}</Title>
              {/* <img src={logo} alt='' style={{ width: '190px' }} /> */}
              <Title2>{title2}</Title2>
            </Container>

            <GridRow col={2} mobile width="max-content">
              <Button
                // to={link1}
                to={internalLink}
                onMouseEnter={playAppear}
                onClick={playClick}
                // onClick={() => setShowModal(true)}>
              >
                {' '}
                <i className="fa-regular fa-images"></i> &nbsp; Showcase
              </Button>
              {/* <Button to={link1}>View Case Study</Button> */}
              {/* <Button href={link2}>View App</Button> */}
            </GridRow>
          </Col>
        </GridRow>
      </Wrapper>
    </Layout>
  );
};
