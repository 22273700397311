import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import whiteLogo from '../../../app/assets/img/logoSOR.svg?url';
import blackLogo from '../../../app/assets/img/logoSORBlack.svg?url';

import {
  AnimatedText1,
  AnimatedText2,
  CloseButton,
  Hamburger,
  HamburgerBar,
  HeaderStyle,
  LogoShortBefore,
  LogoShort,
  MenuContent,
  Logo,
  NavLink,
  List,
} from './Style';
import LanguageSwitcher from '../../../components/ui/LanguageSwitcher';
import { headerLinks as defaultHeaderLinks } from './headerConfig';
import { useUIStore } from '../../../components/hooks/useUIStore';

const TextAnimation = React.memo(() => {
  const navigate = useNavigate();
  const [showTextAnimation1, setShowTextAnimation1] = useState(true);
  const [showTextAnimation2, setShowTextAnimation2] = useState(false);

  const handleLogoClick = useCallback(() => navigate('/'), [navigate]);

  const animatedText1Ref = useRef(null);
  const animatedText2Ref = useRef(null);

  useEffect(() => {
    const resetAnimation = (ref) => {
      if (ref.current !== null) {
        ref.current.style.animation = 'none';
        // Force reflow
        void ref.current.offsetWidth;
        ref.current.style.animation = '';
      }
    };

    if (showTextAnimation1) {
      resetAnimation(animatedText1Ref);
      setTimeout(() => {
        setShowTextAnimation1(false);
        setShowTextAnimation2(true);
      }, 8500);
    } else if (showTextAnimation2) {
      resetAnimation(animatedText2Ref);
      setTimeout(() => {
        setShowTextAnimation1(true);
        setShowTextAnimation2(false);
      }, 16000);
    }
  }, [showTextAnimation1, showTextAnimation2]);

  return (
    <div>
      <LogoShortBefore>
        <LogoShort onClick={handleLogoClick}></LogoShort>
        <div id="diagonalBar" />
      </LogoShortBefore>
      <AnimatedText1 show={showTextAnimation1} ref={animatedText1Ref}>
        SOR SEO
      </AnimatedText1>
      <AnimatedText2 show={showTextAnimation2} ref={animatedText2Ref}>
        systematic organic results
      </AnimatedText2>
    </div>
  );
});

const Header = React.memo(({ setMenuOpened }) => {
  const { menuIsOpen, toggleMenu } = useUIStore();
  const [headerLinks, setHeaderLinks] = useState(defaultHeaderLinks);

  const [navigationPending, setNavigationPending] = useState(false);
  const [targetPath, setTargetPath] = useState('');

  const theme = 'light';
  const navigate = useNavigate();
  const location = useLocation();

  const isOurWorkSubroute =
    location.pathname.startsWith('/our-work/') &&
    location.pathname !== '/our-work/';

  const handleMenuCloseAndNavigate = (path) => {
    setTargetPath(path); // Set the path to navigate to
    setNavigationPending(true); // Indicate that navigation is pending
    toggleMenu(); // Close the menu
  };

  useEffect(() => {
    if (!menuIsOpen && navigationPending) {
      // Only navigate after the menu is closed and navigation is pending
      setNavigationPending(false); // Reset pending state
      navigate(targetPath); // Perform the actual navigation
    }
  }, [menuIsOpen, navigationPending, navigate, targetPath]);

  const handleToggle = useCallback(() => {
    setMenuOpened(true);
  }, [setMenuOpened]);

  const menuVariants = {
    open: { x: 0 },
    closed: { x: '100%' },
  };

  const { i18n } = useTranslation();
  const { t } = useTranslation('common');

  const pathMap = useMemo(
    () => ({
      en: {
        '/notre-equipe': '/our-team',
        '/nos-services': '/our-services',
        '/nos-realisations': '/our-work',
      },
      fr: {
        '/our-team': '/notre-equipe',
        '/our-services': '/nos-services',
        '/our-work': '/nos-realisations',
      },
    }),
    [],
  );

  const adjustLinksForLanguage = useCallback(
    (links, lang) => {
      return links.map((link) => {
        if (link.path === '/') {
          return { ...link, path: lang === 'en' ? '/' : `/${lang}` };
        }

        const mappedPath = pathMap[lang][link.path];
        const newPath = mappedPath
          ? `/${lang}${mappedPath}`
          : `/${lang}${link.path}`;
        return { ...link, path: newPath };
      });
    },
    [pathMap],
  );

  useEffect(() => {
    const currentLang = i18n.language.split('-')[0];
    const adjustedLinks = adjustLinksForLanguage(
      [...defaultHeaderLinks],
      currentLang,
    );
    setHeaderLinks(adjustedLinks);
  }, [i18n.language, adjustLinksForLanguage]);

  const switchLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng).then(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const newPath =
          pathSegments.length > 1
            ? `/${lng}/${pathSegments.slice(1).join('/')}`
            : `/${lng}`;
        navigate(newPath);
      });
    },
    [i18n, location.pathname, navigate],
  );

  useEffect(() => {
    return () => {
      if (navigationPending) {
        setNavigationPending(false); // Reset pending state on unmount
      }
    };
  }, [navigationPending]);

  return (
    <>
      {!isOurWorkSubroute && (
        <HeaderStyle isOpen={menuIsOpen}>
          <TextAnimation />

          {window.innerWidth > 768 ? (
            <LanguageSwitcher onClick={() => switchLanguage('en')} />
          ) : null}
          <Hamburger onClick={() => toggleMenu()} isOpen={menuIsOpen}>
            <HamburgerBar />
            <HamburgerBar />
            <HamburgerBar />
          </Hamburger>
        </HeaderStyle>
      )}

      <AnimatePresence wait>
        {menuIsOpen && (
          <MenuContent
            isOpen={menuIsOpen}
            initial="closed"
            animate={menuIsOpen ? 'open' : 'closed'}
            exit="closed"
            variants={menuVariants}
            transition={{ duration: 0.9, ease: 'easeInOut' }}
          >
            <CloseButton onClick={() => toggleMenu()} isOpen={menuIsOpen}>
              <span />
            </CloseButton>
            <List>
              {headerLinks.map(({ path, key }) => (
                <li key={path}>
                  <NavLink
                    to={path}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default behavior to control navigation manually
                      handleMenuCloseAndNavigate(path);
                    }}
                  >
                    {t(key)}
                  </NavLink>
                </li>
              ))}
              <Logo
                src={theme === 'light' ? whiteLogo : blackLogo}
                alt="SOR SEO Logo"
                onClick={() => navigate('/')}
                onError={(e) => {
                  console.error('Image failed to load:', e.target.src);
                  e.target.src = whiteLogo;
                }}
              />
            </List>
            {window.innerWidth < 768 ? (
              <LanguageSwitcher onClick={() => switchLanguage('en')} />
            ) : null}
          </MenuContent>
        )}
      </AnimatePresence>
    </>
  );
});

export default Header;
