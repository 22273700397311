import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';

import Col from '../../components/layout/Col';
import Button from '../../components/ui/Button';
import { SubmitButton } from '../../features/ThreeJsHtmlExperience/Interface/Style';

const InputContainer = styled.div`
  position: relative;
  padding-top: 15px; // Adjust based on your design
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid #ccc; // Default state border
  padding: 8px;
  font-size: 16px;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom-color: #afcbe2; // Focus state border
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -5px; // Adjust based on your design
    font-size: 12px; // Adjust based on your design
    color: #afcbe2; // Label color when active
  }
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: 30px; // Initial label position
  left: 0;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease-out;
`;

const FloatingLabelInput = ({ label, id, ...props }) => {
  return (
    <InputContainer>
      <StyledInput id={id} placeholder=" " {...props} />
      <FloatingLabel htmlFor={id}>{label}</FloatingLabel>
    </InputContainer>
  );
};

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  max-width: 50rem;
  margin: auto;
`;

export const Layout = styled.div`
  /* z-index: 1; */
  position: relative;

  input,
  form {
    width: 100%;
    max-width: 40rem;
  }

  input::placeholder {
    font-size: 1.65rem;
  }

  input {
    font-size: 1.9rem;
    margin: 0.05rem 0;
  }

  .forgot-password {
    margin: 0;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    margin: auto;
    height: 100%;
    background: ${({ mainImage }) =>
      `url(${mainImage}) no-repeat center center/cover`};
    /* background: blue; */
    z-index: -1;
  }
`;

export const StyledFieldError = styled.div`
  margin-bottom: 1rem;
  font-size: 1.45rem;
`;

export const StyledFormError = styled.div`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.05rem;
`;

export const LoadingIndicator = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
`;

export const Title = styled.h1`
  font-size: 2.7rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 500;
`;

export const Subtitle = styled.p`
  font-size: 1.35rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const ClientLogin = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const [view, setView] = useState('loginForm');
  const [showSpinner, setShowSpinner] = useState(false);
  // const isLoggedIn = useSelector((state) => state.auth.isAuthenticated)
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const navigate = useNavigate();

  const fields = [
    { label: 'Username', name: 'username', type: 'text', required: true },
    { label: 'Password', name: 'password', type: 'password', required: true },
  ];

  const initialValues = {
    username: '',
    password: '',
  };

  // const handleSubmit = async (formData) => {
  //   try {
  //     const proxyURL = `http://localhost:5000`
  //     const response = await axios.post(`${proxyURL}/api/login`, formData)
  //     setResponseMessage(response.data.message)
  //   } catch (error) {
  //     setResponseMessage('Login failed. Please try again later.')
  //   }
  // }

  // useEffect(() => {
  //   sendPageView()
  // }, [])

  //////////////////////////

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().min(6).max(20).required(),
    }),

    onSubmit: async (values) => {
      setShowSpinner(true);
      setFormErrorMessage('');
      try {
        // let authDispatchFunction = await dispatch(signIn(values))
        // await authService.login(values);
        // setView('logInSuccess')
        // navigate('/app');
        // setTimeout(authDispatchFunction, 1800)
      } catch (err) {
        console.log('login failed', err);
        // setFormErrorMessage(err.message);
        setFormErrorMessage('There was an error logging in. Please try again.');
      }

      setShowSpinner(false);
    },
  });

  return (
    <Layout>
      <Container>
        <Title>Client Portal</Title>
        <Subtitle>
          Welcome back! Please log in to access your dashboard.
        </Subtitle>
        {/* <DynamicForm fields={fields} onSubmit={handleSubmit} initialValues={initialValues} buttonText='Login' /> */}

        <>
          <form onSubmit={formik.handleSubmit}>
            {formErrorMessage && (
              <StyledFormError>{formErrorMessage}</StyledFormError>
            )}

            <Col
              width="100%"
              className="group"
              margin="0.4rem 0"
              marginTop="0.4rem"
            >
              <FloatingLabelInput
                type="email"
                name="email"
                id="email"
                label="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <StyledFieldError>{formik.errors.email}</StyledFieldError>
              )}
            </Col>
            <Col
              width="100%"
              className="group"
              margin="0.4rem 0"
              marginTop="0.4rem"
            >
              <FloatingLabelInput
                type="password"
                name="password"
                id="password"
                label="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password && (
                <StyledFieldError>{formik.errors.password}</StyledFieldError>
              )}{' '}
              {showSpinner ? (
                <LoadingIndicator />
              ) : (
                <SubmitButton
                  type="submit"
                  style={{ margin: 'auto !important' }}
                >
                  Log in
                </SubmitButton>
              )}
            </Col>
            {/* <span className="forgot-password">Forgot Password?</span> */}
          </form>
        </>

        {responseMessage && <p>{responseMessage}</p>}
      </Container>
    </Layout>
  );
};
