import GetStartedCTA from '../../features/ctas/GetStartedCTA';
import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 769px) {
    padding: 2rem 4rem;
    padding-top: 10vh;
  }
`;

export const Contact = ({}) => {
  return (
    <Layout>
      <GetStartedCTA />;
    </Layout>
  );
};
