import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Layout } from './Style';
import GridRow from '../../../../components/layout/GridRow';
import Col from '../../../../components/layout/Col';
import {
  Label,
  List,
  ListItem,
  Title,
  Title2,
} from '../../PortfolioItemOverview';
import BackIcon from '../../../../components/ui/icons/BackIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 6vh;
`;

const FullWidthImage = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  object-fit: cover;
  z-index: 1;
  border-radius: 1.2rem;
`;

const OverlayImage = styled(FullWidthImage)`
  position: absolute;
  bottom: -30px; // Adjust as needed
  right: 20px; // Adjust as needed
  width: 20%; // Adjust size as needed
  border-radius: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Optional shadow for better visibility
`;

const Location = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #000;
`;

const P = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 2rem 0;
  padding: 0;
`;

const PortfolioItem = () => {
  const { t, i18n } = useTranslation('ourWork');
  const { slug } = useParams();
  const navigate = useNavigate();

  const projectsTranslations = useMemo(
    () => t('projects', { returnObjects: true }),
    [t, i18n.language],
  );

  const projects = useMemo(
    () =>
      Object.keys(projectsTranslations).map((key) => ({
        ...projectsTranslations[key],
      })),
    [projectsTranslations],
  );

  const matchedItem = useMemo(
    () => projects.find((item) => item.slug === slug),
    [projects, slug],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (!matchedItem) {
    return <p>Portfolio item not found</p>;
  }

  const {
    services,
    title,
    title2,
    location,
    label,
    link1,
    link2,
    mainImage,
    images,
    highlights,
    mobileImages,
    description,
  } = matchedItem;

  return (
    <Layout mainImage={mainImage}>
      <Container>
        <GridRow margin="0" colTemplate="4fr 6fr" mobile>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 2,
            }}
            onClick={() => navigate(t('navigateLink'))}
          >
            <BackIcon /> &nbsp;BACK
          </div>
        </GridRow>
        <Col width="1100px" height="fit-content">
          <FullWidthImage src={images[0]} />
        </Col>
        <Col width="700px" margin="4rem 0">
          <Title>{title}</Title>
          <Title2>{title2}</Title2>
          <P>{description}</P>
        </Col>
        <Col
          width="1100px"
          height="fit-content"
          style={{ position: 'relative' }}
        >
          <FullWidthImage src={images[1]} />
          {mobileImages && mobileImages[0] && (
            <OverlayImage src={mobileImages[0]} alt="Mobile view" />
          )}
        </Col>
        <Col width="900px" justify="flex-start" margin="4vh 0">
          <b>
            <P>Services:</P>
          </b>
          <List>
            {highlights.map((highlight, index) => (
              <ListItem key={`highlight-${index}`}>{highlight}</ListItem>
            ))}
          </List>
        </Col>
        {images &&
          images.slice(2).map((image, index) => (
            <Col
              key={`image-col-${index}`}
              width="1100px"
              height="fit-content"
              style={{ position: 'relative', marginBottom: '20vh' }}
            >
              <FullWidthImage src={image} />
              {mobileImages && mobileImages[index + 1] && (
                <OverlayImage src={mobileImages[index + 1]} alt="Mobile view" />
              )}
            </Col>
          ))}
      </Container>
    </Layout>
  );
};

export default PortfolioItem;
