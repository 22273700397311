export const lightTheme = {
  body: '#f1f1f1',
  text: '#121620',
  primary: '#AFCBE2',
};
export const darkTheme = {
  body: '#333d46',
  text: '#AFCBE2',
  primary: '#AFCBE2',
};

export const themeSettings = {
  light: {
    colors: {
      name: 'light',
      primary: '#0F1012',
      secondary: '#f2f2f4',
      tertiary: '#27292b',

      primary2: '#4c7e7d',
      primary3: '#3d6564',

      text: '#2e2e39',
      body: '#fafafa',
      body2: '#f1f1f1',

      fadedText: '#67666a',
      highlight: '#FFE07D',
      bright: '#F8C7CC',
      underline: '#247BA0',

      danger: '#DA2C38',
      warning: 'yellow',
      success: '#43cd80',

      grey: '#ACA9A9',
    },
  },
  dark: {
    colors: {
      name: 'dark',

      primary: '#d74e55',
      secondary: '#78dde2',
      tertiary: '#a3eabe',
      quaternary: '#ffae42',

      primary2: '#4c7e7d',
      primary3: '#3d6564',

      text: '#fafafa',
      body: '#0F1012',
      // body2: '#212325',
      body2: '#171717',

      fadedText: '#67666a',
      highlight: '#FFE07D',
      bright: '#F8C7CC',
      underline: '#247BA0',

      danger: '#DA2C38',
      warning: 'yellow',
      success: '#43cd80',
      grey: 'grey',
    },
  },

  transitionSpeed: '400ms',

  fontFamily: 'Nunito Sans, Arial, sans-serif',
  fontSize: {
    small: '14px',
    medium: '16px',
    large: '18px',
    extraLarge: '24px',
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    bold: 700,
  },

  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  zIndex: {
    low: 1,
    medium: 5,
    high: 10,
    overlay: 100,
    modal: 1000,
  },
};

export default themeSettings;
