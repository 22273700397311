import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuthStore } from '../../pages/auth/store/authStore';
import { Role } from '../../types/roles';

interface PrivateRouteProps {
  requiredRole?: Role;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ requiredRole }) => {
  const { isAuthenticated, role } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    role: state.role,
  }));

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && role !== requiredRole) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
