import styled from 'styled-components';

// export const Signature = styled.span.attrs({
//   className: 'custom-font-class-abu-dhabi',
// })`
export const Signature = styled.span`
  /* font-family: 'Rhodes'; */
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14rem')};
  font-family: 'Abu Dhabi';
  line-height: 0.82;
  vertical-align: bottom;
  /* color: ${({ color }) => (color ? color : '#fff')}; */
  color: ${({ theme }) => theme?.colors?.text || 'defaultColor'};

  /* font-family: Arial, sans-serif; */
  /* visibility: hidden;
  transition: visibility 0s, opacity 0.5s linear; */

  &body.font-loaded & {
    visibility: visible;
    font-family: 'Abu Dhabi', Arial, sans-serif;
  }

  @media screen and (max-width: 768px) {
    font-size: 8rem;
    font-family: 'Abu Dhabi';

    vertical-align: center;
    font-weight: 100;
    display: inline-block;
  }
`;

export default Signature;
