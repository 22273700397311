import React, { useState } from 'react';
import styled from 'styled-components';
import Signature from '../../../components/ui/elements/Signature';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CTASection = styled.div.attrs({
  className: 'body2',
})`
  // image wold be better I think... (grey feels just ok..)

  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 40px; */
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    /* padding: 10px; */
  }
`;

const CTAHeading = styled.h2.attrs({
  // className: 'text-gradient',
})`
  max-width: 75rem;
  font-size: 4.5rem;
  text-align: center;
  /* letter-spacing: 0.5px; */
  /* font-weight: 100; */

  @media (max-width: 768px) {
    font-size: 2.25rem;
    text-align: left;
    margin: 0;
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 0 20px;
  max-width: 500px;

  &:first-child {
    margin-right: 20px;
  }

  &:nth-child(2) {
    padding: 0 4rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      padding: 0 0rem;
      margin-top: 5vh;
      width: 100%;
    }

    padding: 0;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;

  /* 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px; // Set a max width for better readability
  margin: auto;

  input,
  textarea {
    font-size: 1.6rem;
    padding: 1.2rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    width: 100%;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
      border-color: #1b75bc;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }

  textarea {
    height: 150px; // Larger area for messages
  } */
`;

const Label = styled.label`
  margin-bottom: 6px;
  color: lightgrey;
  width: 100%;
  /* max-width: 35rem; */
  font-size: 1.05rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #8fa7ba;
  border-radius: 4px;
  background: #afcbe2;

  &:focus {
    border-color: white;
    outline: none;
  }
`;

const Textarea = styled.textarea`
  width: 100%; // Set the width to 100% of the parent container
  padding: 10px; // Add some padding for better readability
  margin-bottom: 10px;

  border-radius: 4px; // Rounded corners
  font-size: 1rem; // Font size
  line-height: 1.5; // Line height for better readability
  resize: vertical; // Allows the user to vertically resize the textarea
  min-height: 100px; // Minimum height of the textarea
  border: 1px solid #8fa7ba;
  border-radius: 4px;
  background: #afcbe2;
  &:focus {
    border-color: white;
    outline: none;
  }

  @media and screen(max-width: 768px) {
    width: 100%;
  }
`;

const FormButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 1.7rem;
  cursor: pointer;
`;

const Layout = styled.div`
  text-align: center;
`;

const Button = styled.button`
  border-radius: 4px;

  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1.6rem 2.4rem;
  /* text-transform: uppercase; */
  transition: all 0.3s ease;
  width: 100%;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px 0;
  margin-top: 30px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  /* filter: drop-shadow(0px 2px 5px rgba(255, 255, 255, 0.7)); */
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.7); */
  background: transparent;
  color: var(--primary);
  color: #afcbe2;
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.2);
  opacity: 0.8;
  /* width: 100%; */
  /* max-width: 30rem; */
  /* margin: 0 auto; */
  // on hover

  transition: 'all 0.2s ease-in-out';

  &:hover {
    background-color: #afcbe2;
    color: #153147;
    /* text-shadow: 1.5px 1px 1.5px rgba(0, 0, 0, 0.4); */
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.3);
    opacity: 1;
  }
`;

const StyledButton = styled(motion.button)`
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 10px 20px;

  margin: 10px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  /* filter: drop-shadow(0px 2px 5px rgba(255, 255, 255, 0.7)); */
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.7); */
  /* color: #6e808c; */
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.3);
  opacity: 0.8;
  width: 100%;
  /* max-width: 20rem; */
  margin: 0 auto;
  background: transparent;
  color: var(--primary);
  color: #afcbe2;
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.2);
  opacity: 0.8;
  // on hover

  transition: 'all 0.2s ease-in-out';

  /* &:hover {
    background-color: rgba(255, 255, 255, 0.9);

    color: white;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.7);
    opacity: 1;
  } */

  &:hover {
    background-color: #afcbe2;
    color: #153147;
    /* text-shadow: 1.5px 1px 1.5px rgba(0, 0, 0, 0.4); */
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.3);
    opacity: 1;
  }
`;

export const Subheading = styled.span.attrs({
  // className: 'text-gradient',
})`
  font-weight: 300;
  /* color: white; */
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    /* text-align: justify; */
  }
`;

export const GetStartedCTA = () => {
  const { t } = useTranslation('home');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const proxyURL =
      process.env.NODE_ENV === 'production'
        ? 'https://sor-seo.com'
        : 'http://localhost:5000';
    try {
      const response = await axios.post(`${proxyURL}/api/contact`, formData);
      if (response.data.message) {
        setResponseMessage(response.data.message);
      }
    } catch (error) {
      setResponseMessage(t('getStarted.error'));
    }
  };

  return (
    <CTASection>
      <CTAHeading>{t('getStarted.ctaHeading')}</CTAHeading>
      <Columns>
        <Column>
          <Subheading>
            {t('getStarted.subHeading')}
            <Button
              onClick={() =>
                window.open('https://meetbit.io/sorseo/CHVXyxSgrl0b', '_blank')
              }
            >
              {t('getStarted.bookingButtonText')}
            </Button>
          </Subheading>
        </Column>
        <Column>
          <Subheading>{t('getStarted.contactSubHeading')}</Subheading>
          {responseMessage ? (
            <center>
              <Subheading>{responseMessage}</Subheading>
            </center>
          ) : (
            <ContactForm onSubmit={handleSubmit}>
              <Label>
                {t('getStarted.labels.name')}
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Label>
              <Label>
                {t('getStarted.labels.email')}
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Label>
              <Label>
                {t('getStarted.labels.message')}
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Label>
              <StyledButton type="submit">
                {t('getStarted.sendButton')}
              </StyledButton>
            </ContactForm>
          )}
        </Column>
      </Columns>
    </CTASection>
  );
};

export default GetStartedCTA;
