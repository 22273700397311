import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph, Section, SubHeading } from '../PrivacyPolicy';

const TermsConditionsContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 13vh;
  margin-bottom: 7vh;
  font-family: 'Work Sans', 'Arial', sans-serif;
  font-size: 1.4rem;
`;

const TermsConditions = () => {
  return (
    <TermsConditionsContainer>
      <Heading>Terms & Conditions</Heading>

      <Section>
        <SubHeading>Acceptance of Terms</SubHeading>
        <Paragraph>
          By accessing and using SOR SEO's website and services, you accept and
          agree to be bound by the terms and provision of this agreement.
          Additionally, when using these particular services, you shall be
          subject to any posted guidelines or rules applicable to such services.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Modifications of Terms</SubHeading>
        <Paragraph>
          SOR SEO reserves the right to update the terms and conditions at any
          time without notice to you. The most current version of the terms can
          be reviewed by clicking on the "Terms & Conditions" hypertext link
          located at the bottom of our web pages.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Account Responsibilities</SubHeading>
        <Paragraph>
          You are responsible for maintaining the confidentiality of your login
          credentials, and you are fully responsible for all activities that
          occur under your account. You agree to immediately notify SOR SEO of
          any unauthorized use of your account or any other breach of security.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Intellectual Property Rights</SubHeading>
        <Paragraph>
          The content, organization, graphics, design, compilation, and other
          matters related to SOR SEO's website are protected under applicable
          copyrights, trademarks, and other proprietary rights. The copying,
          redistribution, use, or publication by you of any such matters or any
          part of the site is strictly prohibited. You do not acquire ownership
          rights to any content, document, or other materials viewed through the
          site. The posting of information or materials on the site does not
          constitute a waiver of any right in such information and materials.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Limitation of Liability</SubHeading>
        <Paragraph>
          SOR SEO and its affiliates shall not be liable for any loss, injury,
          claim, liability, or damage of any kind resulting in any way from (a)
          any errors in or omissions from the site or any services obtainable
          therefrom, (b) the unavailability or interruption of the site or any
          features thereof, (c) your use of the site, (d) the content contained
          on the site, or (e) any delay or failure in performance beyond the
          control of a Covered Party.
        </Paragraph>
      </Section>
    </TermsConditionsContainer>
  );
};

export default TermsConditions;
