import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  .top-0 {
    top: 0;
  }

  .absolute {
    position: absolute;
  }

  #canvas-container {
    cursor: circle rgba(255, 255, 255, 0.75) 5px 5px 10px; /* Adjust as per your preference */
  }
`;

export const TextBox = styled(motion.div)`
  /* display: ${({ imageActive }) => (imageActive ? 'none' : 'flex')}; */
  position: absolute;
  /* background: green; */
  /* color: ${({ imageActive, theme }) => (imageActive ? 'white' : 'black')}; */
  color: white;
  font-weight: 600;
  left: 50%;
  top: 70%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 60rem;
  line-height: 1.5;
  z-index: 1;
  transition: all 0.55s ease-in-out;
  transition-delay: 3s;

  @media screen and (max-width: 768px) {
    width: 81%;
    font-size: 1.25rem;
  }
`;

export const ClippedText = styled.span`
  display: inline-block;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-clip: text;
  color: transparent;
`;

export const StyledSection = styled(motion.section)`
  position: relative;
  min-height: 100vh;
  width: 100%;

  h2 {
    color: white;
  }
`;

export const SectionWrapper = styled.div`
  position: relative;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '100vh')};
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${({ width }) => (width ? width : '70%')};
  margin-left: ${({ marginLeft }) => marginLeft ?? '8%'};
  /* margin-right: ${({ marginRight }) => marginRight ?? '30%'}; */
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  margin: ${({ margin }) => margin ?? '0 auto'};
  z-index: 1;
  transition: all 0.5s ease-in-out;

  ${({ imageActive }) =>
    imageActive &&
    css`
      h2 {
        color: ${({ theme }) => theme.colors.text};
        transition: all 0.25s ease-in-out;
      }
    `}

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
    width: 95%;
    margin-left: ${({ marginLeft }) => marginLeft ?? '0rem'};
    margin: auto;
    /* margin-left: 3rem; */
  }
`;

export const SectionTitle = styled.h2`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '10rem')};
  font-weight: 100;
  display: inline-block;

  margin: ${({ margin }) => margin ?? ''};
  line-height: 1.05;

  > span  {
    color: white;
  }

  @media screen and (max-width: 768px) {
    font-size: 5rem;
  }
`;
// add a classname to the below div
export const SectionContent = styled.div`
  font-size: 1.8rem;
  /* color: ${({ theme }) => (theme === 'light' ? 'black' : 'white')}; */

  color: ${({ theme }) => theme.colors?.text || '#e2e2e2'};

  width: ${({ contentWidth }) => (contentWidth ? contentWidth : '70%')};
  margin: 0 auto;
  margin-top: ${({ contentMarginTop }) =>
    contentMarginTop ? contentMarginTop : '7rem'};
  line-height: 2.15rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AnimatedLetter = animated.span;
