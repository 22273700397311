import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

// to put in shallowEqual.js
const shallowEqual = (objA, objB) => {
  if (objA === objB) {
    return true;
  }
  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }
  return true;
};

export const defineStore = (state, actionsCreator) => {
  return create(
    subscribeWithSelector(
      (set, get) => {
        const actions = actionsCreator(set, get);
        return { ...state, ...actions };
      },
      {
        selector: shallowEqual,
      },
    ),
  );
};
