import Arrow from '../../../app/assets/icons/arrowBotLeft.svg';
import IconWrapper from './style';

export const ArrowBotLeft = ({
  size = '24px',
  color = '#afcbe2',
  onClick,
  ...props
}: {
  size?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <IconWrapper
      size={size}
      onClick={onClick}
      color={color}
      {...props}
      cursor="pointer"
    >
      <Arrow />
    </IconWrapper>
  );
};

export default ArrowBotLeft;
