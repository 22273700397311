import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph, Section, SubHeading } from '../PrivacyPolicy';

// Styled Components
const LegalNoticeContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 13vh;
  margin-bottom: 7vh;
  font-family: 'inherit', 'Arial', sans-serif;
  font-size: 1.4rem;
`;

const LegalNoticeCookies = () => {
  return (
    <LegalNoticeContainer>
      <Heading>Legal Notice & Cookies</Heading>

      <Section>
        <SubHeading>Legal Information</SubHeading>
        <Paragraph>
          The information contained on SOR SEO's website is for general
          information purposes only. SOR SEO assumes no responsibility for
          errors or omissions in the contents of the Service.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Use of Cookies</SubHeading>
        <Paragraph>
          Our website uses cookies to enhance the user experience and collect
          data for website analytics. By using our website, you consent to the
          use of cookies in accordance with our Privacy Policy.
        </Paragraph>
      </Section>

      {/* Additional sections as necessary for legal notices and detailed cookie policy */}
    </LegalNoticeContainer>
  );
};

export default LegalNoticeCookies;
