import { motion } from 'framer-motion';
import styled from 'styled-components';
import Col from '../../components/layout/Col';

const Section = styled.section`
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
  min-height: ${(props) => props.minHeight || '45vh'};
  text-align: left;
  color: #afcbe2 !important;

  p {
    text-align: left;
    margin-right: auto;
    font-size: 1.6rem;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #5d5d5d;
  animation: undulate 0.5s linear infinite;

  @keyframes undulate {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const LineContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UndulatingLines = () => {
  return (
    <LineContainer>
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
    </LineContainer>
  );
};

const SectionWithUndulatingLines = ({ children }) => {
  return (
    <>
      <UndulatingLines />
      {children}
    </>
  );
};

const MajorQuote = styled(motion.p)`
  font-size: 2.5rem;
  margin: 5rem auto;
  color: #afcbe2 !important;
`;

const StyledAbmGuideDefinitionsPage = styled.article`
  margin-top: 26vh;

  /* General styles for all pages */
  h1 {
    font-size: 3.6rem;
  }

  h2 {
    font-size: 2.5rem;
    margin-right: auto;
  }

  h4 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .reference {
    font-size: 1.2rem;
    color: #afcbe2;
  }

  ul {
    position: relative;
    margin: auto 0;
    font-size: 1.6rem;
    li {
      left: 0rem;
      transform: translateX(-0rem);
      transition: all 0.35s ease-in-out;
      &:hover {
        &:before {
          content: '> ';
          font-weight: bold;
          position: absolute;
          left: -2rem;
          transform: translateX(-5rem);
          transition: all 0.35s ease-in-out;
        }
        margin: 0 5px 0 -15px;
        color: #f00;
      }
    }
  }

  @media (min-width: 900px) {
    padding-bottom: 10rem;
    .col {
      width: 55%;
    }

    .major-quote {
      width: 70%;
    }
  }

  /* Styles specific to this ABM Guide page */

  /* Add the following styles to implement the progress bar */
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #5d5d5d;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.5s ease-in-out;
  }

  &:hover:before {
    transform: scaleX(1);
  }
`;

const SectionMod = styled(Section)`
  margin: 3rem 0;
  min-height: fit-content;
  align-items: flex-start;

  h2 {
    color: #5d5d5d;
    font-size: 1.9rem;
  }

  p {
    margin: 1.5rem 0;
  }
`;

export const AccountBasedMarketing = () => {
  return (
    <StyledAbmGuideDefinitionsPage>
      {/* <VerticalProgressBarPageSideMenu />
      <BackButton to='/resources' /> */}
      <Col align="flex-start" className="col">
        <h1 style={{ margin: '0' }}>Account-based Marketing & B2B Growth</h1>
        <h4>Overview guide & definitions</h4>
        <Section margin="0rem 0">
          <h2>A sustainable and scalable approach to B2b growth</h2>
          <p>
            Account-based Marketing is a strategic approach in B2B Marketing,
            where the main focus is high value targeted accounts.
          </p>
          <p>ABM is a focused growth strategy.</p>
          <p>
            ABM gives you full control over the main storylines experienced by
            your buyers in their journey.
          </p>
        </Section>
        <Col>
          <MajorQuote
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <span className="scaled-font">
              <i className="fa-solid fa-quote-left"></i> 80% of B2B companies
              report that ABM increases their customer lifetime value.
            </span>
          </MajorQuote>
        </Col>

        <Section>
          <h2>The need to embrace a new organizational culture</h2>
          <p>
            Many CMOs understand that they’ll need to focus on disruptive
            growth, what often holds many of them back is the current culture of
            their organizations.
          </p>
          <p>
            Modern buyers have higher expectations toward brands: they need to
            stand for something bigger than the products/services they sell.
          </p>
          <p>
            They need to inspire, set new standards, have a clear purpose and
            emphasize certain values.
          </p>
          <p className="reference">
            <strong>reference: </strong>
            Living Business Report - Accenture
          </p>
        </Section>

        <Section>
          <h2>The implicit value of Account-based Marketing:</h2>
          <ul>
            <li>Shorter Sales Cycles</li>
            <li>Higher Time Investment Value</li>
            <li>Easier Evaluation of ROI</li>
            <li>Sales and Marketing Team Alignment</li>
          </ul>
        </Section>
        <Section>
          <Col>
            <MajorQuote
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <span className="scaled-font">
                <i className="fa-solid fa-quote-left"></i> 87% of marketers who
                measure ROI say ABM outperforms every other marketing
                investment.
              </span>
            </MajorQuote>
          </Col>
        </Section>

        <Section>
          <h2>A definitive trend</h2>
          <div className="key-stats-Section" id="key-stat-images">
            <p className="key-stat">
              <p>
                The trend of Account-based Marketing (ABM) has seen significant
                growth in recent years, as more and more B2B companies adopt
                this approach to their marketing strategy. According to a report
                from LinkedIn, ABM budgets now account for 36% of total digital
                marketing spend, and this percentage is growing at an annual
                rate of 9%. This demonstrates the increasing popularity of ABM
                among B2B marketers, who recognize the benefits and efficiency
                of this targeted approach.
                <br />
                <br />
                ABM focuses on identifying and targeting specific high-value
                accounts, instead of trying to reach a wider audience through
                generic marketing methods. This allows marketers to personalize
                their message and create a more tailored experience for their
                target accounts, which has been shown to result in increased
                engagement and improved conversion rates.
                <br />
                <br />
                The growing adoption of ABM is also being driven by the changing
                nature of the B2B sales process. With modern buyers becoming
                increasingly informed and selective, B2B companies are
                recognizing the need to shift away from traditional outbound
                marketing methods and instead focus on building relationships
                and engaging with their target accounts in a more meaningful
                way.
                <br />
                <br />
                Overall, the growth in ABM budgets and its increasing popularity
                among B2B marketers highlights the success and effectiveness of
                this approach to B2B growth. By targeting specific high-value
                accounts, B2B companies can drive more efficient and effective
                marketing campaigns, improve their ROI, and ultimately achieve
                more sustainable and scalable growth.
              </p>
            </p>
          </div>
        </Section>
        <Col>
          <MajorQuote
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <i class="fas fa-quote-left"></i>&nbsp;The whole point of an ABM
            strategy is to present your prospects with the information they
            need, instead of having them search for it.
          </MajorQuote>
        </Col>
        <Section>
          <div className="quotes">
            <h2>Customer centric approach</h2>
            <p>
              The modern organizations hold the customer central to their
              thinking and vision, not just in the services they provide, but in
              how they pivot and adapt as a company. They create what we call a
              Living Business: one that continuously adapts at speed and scale
              to achieve total customer relevance and sustained growth.
            </p>
            <span className="reference">
              <strong>reference: </strong>
              Living Business Report - Accenture
            </span>
          </div>
        </Section>
      </Col>
    </StyledAbmGuideDefinitionsPage>
  );
};

export default AccountBasedMarketing;
