import axios from 'axios';
import { useAuthStore } from '../../../pages/auth/store/authStore';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

api.interceptors.request.use(
  (config) => {
    const { token } = useAuthStore.getState();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token: string) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const { data } = await api.post('/auth/refresh');
        const newToken = data.accessToken;
        const newRefreshToken = data.refreshToken;

        useAuthStore
          .getState()
          .setToken(newToken, newRefreshToken, Date.now() + 15 * 60 * 1000);
        Cookies.set('token', newToken);
        Cookies.set('refreshToken', newRefreshToken);
        Cookies.set('tokenExpiry', (Date.now() + 15 * 60 * 1000).toString());

        api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

        isRefreshing = false;
        onRefreshed(newToken);

        return api(originalRequest);
      } catch (err) {
        useAuthStore.getState().logout();
        isRefreshing = false;
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
