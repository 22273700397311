import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import { motion } from 'framer-motion';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { easeInOutCubic } from '../../../utils/animation';

export const HeaderStyle = styled.header`
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75rem;
  height: 5.35rem;
  padding: 1.3rem 2rem;
  margin: auto;
  z-index: 5;
  border-radius: 1.25rem;

  opacity: 0.95;

  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.45s ease-in-out;

  transform: translateX(${({ isOpen }) => (isOpen ? '210%' : '0')});
  transition-delay: ${({ isOpen }) => (isOpen ? '0s' : '0.8s')};
  /* 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    bottom: 0;
    border: 2px solid pink;
  } */

  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem;
    height: 5rem;
    width: 85%;
  }
`;

export const MenuContent = styled(motion.div)`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100vh;

  flex-direction: column;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.95); */
  background-image: linear-gradient(
    230deg,
    rgba(32, 34, 36, 1) 0%,
    #161616 100%
  );
  background: #153147;

  padding: 1rem;
  padding-left: 3rem;
  transition: transform 1s cubic-bezier(0.68, -0.06, 0.32, 1.05);
  transition-delay: ${({ isOpen }) => (isOpen ? '0.2s' : '0s')};
  transform: translateX(0);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  opacity: 0.98;

  /* opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s; */
  /* transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '100%')}); */
  /* 
  & > a {
    margin: 0.5rem 0;
    font-size: 2.5rem; // Increased font size for better readability in a panel
  } */

  .fa-circle-half-stroke {
    color: #323232;
    margin-top: 0.5rem;
    font-size: 1.8rem;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      transition: all 0.25s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const slideAnimation = keyframes`
 0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1; transform: translateX(0%);
  transform: translate(0%,-50%); } 
  60% { opacity: 1; transform: translateX(0%); transform: translate(0%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-300%,-50%) }
`;

export const slideAnimationMobile = keyframes`
 0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1;
   /* transform: translateX(0%); */
  transform: translate(48%,-50%); } 
  60% { opacity: 1; 
    /* transform: translateX(48%); */
     transform: translate(48%,-50%); } 
  100% { opacity: 0; 
  /* transform: translateX(100%); */
   transform: translate(-300%,-50%) }
`;

export const slideAnimation2 = keyframes`
  0% { opacity: 0; transform: translateX(-100%); transform: translate(-50%,-50%); } 
 40% { opacity: 1; transform: translateX(0%);
  transform: translate(0%,-50%); } 
  60% { opacity: 1; transform: translateX(0%); transform: translate(0%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-350%,-50%) }
`;

export const slideAnimation2Mobile = keyframes`
  0% { opacity: 0;  transform: translate(-50%,-50%); } 
 40% { opacity: 1;
  transform: translate(16%,-50%); } 
  60% { opacity: 1; transform: translate(16%,-50%); } 
  100% { opacity: 0; transform: translateX(100%); transform: translate(-350%,-50%) }
`;

export const AnimatedText = styled(animated.div).attrs({
  // className: 'text-gradient',
})`
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(40%, -50%);
  font-size: 1.1rem;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  font-weight: 500;
  white-space: nowrap;

  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  color: #c5c5c5;
  color: #afcbe2;
`;

export const Container = styled.div`
  /* position: fixed; */
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40rem;

  z-index: 999;
  overflow: hidden;
  transform: translateX(${({ open }) => (open ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    transform: translateX(${({ open }) => (open ? '0' : '100%')});
  }
`;

export const Logo = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateX(-50%) translateY(-5%);
  }
`;

// export const Logo = styled.img`
//   height: 4.5rem;
//   margin-right: 1rem;
//   cursor: pointer;
// `

export const CloseButton = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};

  position: absolute;
  top: 10px;
  right: 20px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  span {
    position: relative;
    width: 24px;
    height: 2px; /* We'll make the span the center bar of the X */
    background: var(--primary);
    display: block;
    transform: translateY(-50%) rotate(45deg); /* Center the span vertically and rotate it */
    top: 50%;
    left: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%; /* Same width as the span */
      height: 100%; /* Same height as the span */

      top: 0; /* Align with the span */
      background: var(--primary);
    }

    &::before {
      transform: rotate(-90deg); /* Perpendicular to the span */
    }

    &::after {
      transform: rotate(90deg); /* Perpendicular to the span */
    }
  }

  i {
    /* The styles for the <i> element can remain unchanged */
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 2rem;
  text-align: left;
`;

export const NavLink = styled(animated(RouterNavLink))`
  margin-bottom: 10px;

  animation: ${fadeIn} 0.5s ease-in-out;
  font-size: 2.15rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: #afcbe2;
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  &.active {
    /* color: pink; */
    font-weight: 500;

    /*
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; */
  }

  &:hover {
    /* background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    transition: all 0.25s ease-in-out; */
    color: #f5f5f5;
  }
`;

// export const NavLink = styled(RouterNavLink)`
//   text-decoration: none;
//   color: black;
//   padding: 0 3rem;
//   // Add more styles as needed
// `

export const HamburgerBar = styled.div.attrs({
  // className: 'gradient-background',
})`
  width: 3rem;
  height: 0.16rem;
  /* margin-right: 0.4rem; */
  margin-left: auto;

  transition: 200ms all ease-in-out;
  /* background-color: #d9d9d9; */
  background-color: #afcbe2;

  &:first-of-type {
    // margin-top: 1rem;
    /* margin-top: 0.05rem; */
    width: 2rem;
  }
  &:nth-of-type(2) {
    margin-top: 0.85rem;
    width: 2.75rem;
    /* height: 0.14rem; */
  }
  &:nth-of-type(3) {
    margin-top: 0.85rem;
    width: 3.5rem;
  }
`;

export const Hamburger = styled.div`
  display: block;
  cursor: pointer;
  margin-left: auto;
  width: 3.5rem;

  &:hover {
    ${HamburgerBar} {
      &:first-of-type {
        width: 3.5rem;
      }
      &:nth-of-type(2) {
        width: 2.75rem;
      }
      &:nth-of-type(3) {
        width: 2rem;
      }
    }
  }
`;

export const swoopAnimation = keyframes`
  0% {
transform: rotate(45deg) translate(-300px, 0); 

}
100% {
transform: rotate(45deg) translate(300px, 0); 
}
`;

export const LogoShortBefore = styled.div`
  display: flex;
  justify-content: flex-end;
  /* content: ''; */
  /* content: url('/logoNewSor.svg'); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 4.75rem;
  height: 100%;
  border-radius: 2.5rem;
  transform: scale(1.05);
  padding: 0.5rem 0;

  #maskRect {
    width: 4px;
    height: 300px;
    background-color: purple;
    position: absolute;
    top: -50px;
    left: 50px;
    transform: rotate(45deg);
    animation: ${swoopAnimation} 3s infinite; // Apply animation here
  }

  /* &:hover {
    transform: scale(1.05);
  } */
`;

// export const LogoShort = styled.img`
//   position: relative;
//   width: 3.5rem;
//   cursor: pointer;
//   transform: translate(-11%, 2px);
//   z-index: 3;
// `

// export default styled.svg.attrs({
const Icon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

const Svg = styled.svg`
  width: 47px;
  height: 47px;
  z-index: auto;
  /* #maskRect {
    animation: ${swoopAnimation} 3s infinite;
  } */
`;

// const AnimatedRect = styled.rect`
//   fill: #f3f3f3; // Adjust the fill as needed
//   animation: ${swoopAnimation} 3s infinite;
// `

const AnimatedMaskRect = styled.rect`
  animation: ${({ isAnimating }) =>
    isAnimating
      ? css`
          ${swoopAnimation} 3s infinite
        `
      : 'none'};
`;
const ClipPath = styled.div`
  clip-path: url(#clip);
`;

export const LogoMarkSvg = ({ className, onClick }) => (
  <Svg
    onClick={onClick}
    viewBox="0 0 47 47"
    className={className}
    style={{
      zIndex: 'auto',
    }}
  >
    <defs>
      <clipPath id="clip">
        <rect x="0" y="0" width="47" height="47" />
      </clipPath>
    </defs>
    <g clip-path="url(#clip)">
      <path
        d="M44.634 3.13144C45.0493 3.26387 45.4336 3.4788 45.7638 3.76335C46.094 4.04791 46.3634 4.39622 46.5557 4.7874C46.748 5.17858 46.8594 5.60458 46.8831 6.03984C46.9068 6.47511 46.8424 6.91068 46.6937 7.32044C45.9104 9.58319 44.0654 12.4958 42.0115 15.5302C38.2867 21.6396 34.9273 26.1071 32.2236 27.6736C31.8838 27.8782 31.5064 28.0124 31.1137 28.0682C30.721 28.124 30.321 28.1003 29.9377 27.9985C27.4603 27.3081 26.0794 23.9952 27.4254 19.2956C29.9899 9.14223 33.7147 3.98433 38.4839 3.35192C40.3014 3.05634 42.1447 2.94939 43.9842 3.0328C44.1903 3.03809 44.395 3.06928 44.5934 3.12563L44.634 3.13144Z"
        fill="#91E7F3"
        fill-opacity="0.85"
      />
      <path
        d="M46.8271 1.51813C46.8627 1.51782 46.8974 1.50659 46.9264 1.48597C46.9555 1.46535 46.9775 1.43633 46.9895 1.40281C47.0015 1.36929 47.0029 1.3329 46.9936 1.29854C46.9843 1.26417 46.9647 1.23349 46.9374 1.21063C46.5719 0.897321 45.5971 0.630436 44.2569 0.398359C41.7563 -0.0193799 36.2328 -0.0077895 35.8151 0.00381435C34.2312 0.0386259 35.1595 1.19902 35.5134 1.50072C36.1342 2.0229 46.8329 1.65157 46.8271 1.51813Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M33.1055 0.694019C32.1308 1.81379 30.2045 2.50422 27.4776 3.28748C27.4414 3.29359 27.4042 3.28677 27.3725 3.26824C27.3408 3.2497 27.3166 3.22063 27.3042 3.18608C27.2917 3.15153 27.2918 3.11371 27.3044 3.07922C27.3171 3.04474 27.3414 3.01576 27.3732 2.99739C29.4038 1.98785 31.3707 1.11176 32.9372 0.44454C32.9695 0.434147 33.0043 0.434522 33.0364 0.44562C33.0685 0.456718 33.0961 0.47793 33.1151 0.506069C33.134 0.534207 33.1434 0.567751 33.1416 0.601646C33.1399 0.635542 33.1272 0.667958 33.1055 0.694019Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M17.9971 12.9364C17.9971 15.8838 20.8981 19.6029 22.8243 20.2933C23.0757 20.3827 23.3503 20.3827 23.6018 20.2933C24.0833 20.1366 24.8028 19.8698 25.7601 17.7636C26.4447 16.261 27.3788 13.1221 27.9996 11.8283C29.16 9.29283 29.9433 7.09391 29.8562 5.52739C29.8489 5.35507 29.8071 5.18598 29.7332 5.03015C29.6593 4.87431 29.5549 4.7349 29.4261 4.62018C29.2973 4.50546 29.1468 4.41778 28.9835 4.36233C28.8202 4.30688 28.6474 4.28479 28.4754 4.29738C25.6614 4.55847 22.1339 7.73211 18.3278 12.0429C18.1128 12.2908 17.9952 12.6083 17.9971 12.9364Z"
        fill="#7BF59E"
        fill-opacity="0.85"
      />
      <path
        d="M20.5388 7.55228C18.9207 9.17398 16.9065 10.3441 14.6963 10.9464C14.649 10.9608 14.5985 10.9608 14.5512 10.9464C13.0717 10.3952 12.0158 9.7338 11.5342 8.92153C11.5151 8.88793 11.5036 8.85051 11.5007 8.81196C11.4978 8.77341 11.5035 8.73469 11.5174 8.69861C11.5313 8.66252 11.553 8.62998 11.581 8.60331C11.609 8.57665 11.6425 8.55652 11.6793 8.54441C14.4871 7.61315 17.424 7.12955 20.3821 7.11133C20.4305 7.11708 20.4762 7.13622 20.5142 7.16659C20.5523 7.19695 20.581 7.23733 20.5973 7.28318C20.6136 7.32903 20.6168 7.37852 20.6064 7.42607C20.5961 7.47362 20.5727 7.51734 20.5388 7.55228Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M10.9139 11.6945C7.68461 11.8139 4.45104 11.7403 1.2305 11.474C1.13684 11.4642 1.04935 11.4226 0.982611 11.3562C0.915875 11.2897 0.873918 11.2024 0.863741 11.1088C0.853564 11.0152 0.875777 10.9209 0.926677 10.8416C0.977577 10.7624 1.0541 10.703 1.14348 10.6733C3.11801 10.0317 5.16803 9.65136 7.2413 9.54195H7.28772C8.72212 9.51591 10.1131 10.0342 11.1808 10.9924C11.2368 11.0486 11.2754 11.1197 11.2919 11.1973C11.3085 11.2749 11.3023 11.3555 11.2741 11.4297C11.246 11.5038 11.197 11.5682 11.1331 11.6152C11.0692 11.6622 10.9931 11.6897 10.9139 11.6945Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M14.5396 15.3611C13.386 15.7504 12.1705 15.9234 10.954 15.8717C10.866 15.861 10.7822 15.8276 10.711 15.7746C10.6398 15.7217 10.5836 15.6512 10.5479 15.57C10.3551 15.1017 10.3551 14.5762 10.5479 14.1079C10.5801 14.0179 10.6403 13.9406 10.7196 13.8873C10.799 13.834 10.8934 13.8076 10.9888 13.812C12.2707 13.8366 13.5188 14.2271 14.5861 14.9376C14.6233 14.9615 14.6532 14.9953 14.6723 15.0352C14.6914 15.0752 14.6989 15.1197 14.6941 15.1637C14.6893 15.2077 14.6723 15.2495 14.645 15.2844C14.6177 15.3193 14.5812 15.3458 14.5396 15.3611Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M8.88289 13.5629C7.67609 13.0987 4.92017 13.0349 1.46222 13.1509C1.40161 13.1572 1.3446 13.1827 1.29961 13.2238C1.25462 13.2649 1.22402 13.3193 1.21232 13.3791C1.20062 13.4389 1.20844 13.5009 1.23463 13.5559C1.26082 13.611 1.304 13.6561 1.35779 13.6847C2.95083 14.414 4.64467 14.899 6.38226 15.1236C6.86098 15.1647 7.34291 15.1004 7.79403 14.9349C8.24515 14.7695 8.65447 14.5071 8.99313 14.1663C9.03611 14.1238 9.06808 14.0714 9.08624 14.0138C9.10441 13.9561 9.10823 13.8949 9.09736 13.8355C9.0865 13.776 9.06128 13.7201 9.02391 13.6726C8.98653 13.6251 8.93812 13.5874 8.88289 13.5629Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M0.510462 14.9206C5.66838 15.8083 9.49185 19.5331 12.2594 25.5033C12.3039 25.5888 12.3193 25.6865 12.303 25.7815C12.2868 25.8765 12.2399 25.9635 12.1695 26.0293C12.0991 26.0951 12.009 26.1361 11.9131 26.1458C11.8173 26.1556 11.7208 26.1337 11.6386 26.0835C8.78982 24.2211 1.56641 17.9608 0.0753176 15.611C0.0273408 15.5392 0.00119756 15.4551 4.01567e-05 15.3687C-0.00111724 15.2824 0.0227666 15.1976 0.0688017 15.1246C0.114837 15.0515 0.181051 14.9934 0.259434 14.9572C0.337818 14.921 0.425006 14.9083 0.510462 14.9206Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M11.2554 20.3805C12.1082 22.9904 13.5448 25.3715 15.456 27.3428C15.6718 27.5835 15.9356 27.7766 16.2303 27.9094C16.5251 28.0423 16.8443 28.1122 17.1676 28.1145H17.7478C18.3139 28.1151 18.8607 27.9087 19.2853 27.5343L20.4457 26.5073C20.7957 26.1879 21.0479 25.7758 21.1732 25.3188C21.2985 24.8618 21.2917 24.3787 21.1535 23.9255C20.0338 20.2354 18.5949 18.1932 16.9936 16.9631C16.7549 16.7776 16.4816 16.6415 16.1897 16.5628C15.8978 16.4841 15.5932 16.4644 15.2936 16.5048C14.2668 16.6499 13.2801 17.0012 12.3926 17.5375C11.9148 17.8184 11.5439 18.25 11.338 18.7646C11.1322 19.2792 11.1031 19.8476 11.2554 20.3805Z"
        fill="#FA6565"
        fill-opacity="0.9"
      />
      <path
        d="M28.4873 29.6345C27.7069 29.8651 26.9066 30.0224 26.0969 30.1045C26.0584 30.11 26.0191 30.1057 25.9827 30.0921C25.9462 30.0784 25.9138 30.0558 25.8884 30.0263C25.863 29.9969 25.8454 29.9615 25.8372 29.9234C25.829 29.8854 25.8306 29.8459 25.8417 29.8086C26.0389 29.0311 26.4219 28.4335 27.1819 28.4277C27.7127 28.5485 28.1942 28.8278 28.5628 29.2284C28.5932 29.2563 28.616 29.2915 28.6291 29.3307C28.6421 29.3699 28.645 29.4117 28.6375 29.4523C28.63 29.493 28.6122 29.531 28.586 29.5628C28.5597 29.5947 28.5258 29.6194 28.4873 29.6345Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M22.2333 28.3636C22.407 28.8307 22.4859 29.3277 22.4654 29.8257C22.4633 29.9161 22.4288 30.0028 22.3681 30.0699C22.3073 30.137 22.2245 30.1801 22.1347 30.1912C21.2788 30.2708 20.4157 30.1639 19.6051 29.8779C19.5417 29.8546 19.4857 29.8149 19.4426 29.763C19.3996 29.711 19.371 29.6486 19.3598 29.5821C19.3486 29.5155 19.3552 29.4472 19.3789 29.384C19.4026 29.3208 19.4426 29.265 19.4948 29.2223C20.1519 28.6135 20.9844 28.2277 21.8736 28.1199C21.9521 28.1148 22.0301 28.1359 22.0953 28.18C22.1604 28.2242 22.209 28.2887 22.2333 28.3636Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M25.6623 46.7033C24.6064 44.7132 24.9371 36.5557 25.3722 31.8793C26.1873 31.8162 27.0039 31.9821 27.7297 32.3583C28.4555 32.7346 29.0618 33.3062 29.48 34.0086C29.3755 38.507 28.0564 42.8934 25.6623 46.7033Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M22.8251 32.083C23.3791 36.9378 23.4452 41.8359 23.0224 46.7039C20.162 42.0102 18.6767 37.798 19.0074 34.2124C19.6514 32.7677 20.9453 32.0772 22.8251 32.083Z"
        fill="white"
        fill-opacity="0.85"
      />
      {/* <AnimatedRect x='-100' y='0' width='4' height='300' transform='rotate(45)' /> */}
    </g>
  </Svg>
);

export const LogoShort = styled(LogoMarkSvg)`
  position: relative;
  width: 3.5rem;
  cursor: pointer;
  transform: translate(10px, -1px);
  z-index: 3;
  > g > path {
    fill: ${({ theme }) => (theme === 'light' ? theme.colors.text : '')};
    fill: #e8e8e8;
    fill: #d9d9d9;
    fill: #d1d1d1;
    fill: #afcbe2;

    z-index: 3;
  }
`;

export const AnimatedText1 = styled(AnimatedText)`
  left: 10%;
  letter-spacing: 2px;
  animation: ${({ show }) => show && slideAnimation} 6.75s forwards;

  @media screen and (max-width: 768px) {
    animation: ${({ show }) => show && slideAnimationMobile} 6.75s forwards;
  }
`;

export const AnimatedText2 = styled(AnimatedText)`
  left: 10%;
  letter-spacing: 1.2px;
  animation: ${({ show }) => show && slideAnimation2} 8.75s forwards;

  @media screen and (max-width: 768px) {
    animation: ${({ show }) => show && slideAnimation2Mobile} 6.75s forwards;
  }
`;
