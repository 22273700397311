import styled from 'styled-components';

export const Layout = styled.div`
  /* background: url('/img/whistlaw-people.jpg'); */
  width: 100%;
  /* min-height: 100vh; */
  height: 100%;
  padding: 2rem 5rem;
  /* margin-top: 13vh; */
  z-index: 0;
  background: #38455d;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 90%;
    width: 96%; */

    /* border-radius: 2rem; */

    opacity: 0.1;
    margin: auto;
  }
  &::before {
    background: ${({ mainImage }) =>
      `url(${mainImage}) no-repeat center center/cover`};
    z-index: 0;
  }

  .image-slider {
    /* background: #000; */
    height: 53vh;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem;

    @media screen and (max-width: 768px) {
      height: 25vh;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 2rem;
  }
`;

export const BackButton = styled.svg`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;
  /* padding: 1.4rem 1.4rem; */
  /* margin-top: 12vh; */
  border-radius: 1.2rem;
  z-index: 2;
  max-width: 40px;

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    /* background: #ebebeb; */
    transition: all 0.2s ease-in-out;
  }
`;

export const P = styled.p`
  color: ${({ theme }) => theme.colors.body};
  width: 100%;
  font-size: 1.5rem;
  z-index: 2;
  position: relative;
`;
