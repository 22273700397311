import { create } from 'zustand';
import api from '../../../util/routing/api';
import { Role } from '../../../types/roles';
import Cookies from 'js-cookie';

interface AuthState {
  loading: boolean;
  error: string;
  token: string | null;
  refreshToken: string | null;
  tokenExpiry: number | null;
  role: Role | null;
  isAuthenticated: boolean;
  setToken: (
    token: string | null,
    refreshToken: string | null,
    expiry: number | null,
  ) => void;
  checkAuth: () => void;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => {
  const token = Cookies.get('token');
  const refreshToken = Cookies.get('refreshToken');
  const tokenExpiry = Cookies.get('tokenExpiry');
  const isAuthenticated =
    token && tokenExpiry && Date.now() < parseInt(tokenExpiry, 10);

  return {
    loading: false,
    error: '',
    token: token || null,
    refreshToken: refreshToken || null,
    tokenExpiry: tokenExpiry ? parseInt(tokenExpiry, 10) : null,
    role: null,
    isAuthenticated: !!isAuthenticated,

    setToken: (
      token: string | null,
      refreshToken: string | null,
      expiry: number | null,
    ) => {
      console.log('Setting token:', { token, refreshToken, expiry });

      if (token) {
        Cookies.set('token', token);
      } else {
        Cookies.remove('token');
      }
      if (refreshToken) {
        Cookies.set('refreshToken', refreshToken);
      } else {
        Cookies.remove('refreshToken');
      }
      if (expiry) {
        Cookies.set('tokenExpiry', expiry.toString());
      } else {
        Cookies.remove('tokenExpiry');
      }
      set({
        token,
        refreshToken,
        tokenExpiry: expiry,
        isAuthenticated: !!token,
      });
    },

    checkAuth: async () => {
      set({ loading: true });
      try {
        const response = await api.get('/auth/check');
        set({ isAuthenticated: response.status === 200, loading: false });
      } catch (error) {
        set({
          isAuthenticated: false,
          loading: false,
          error: 'An error occurred during authentication.',
        });
      }
    },

    login: async (username: string, password: string) => {
      console.log('Checking authentication...');

      set({ loading: true });
      try {
        const response = await api.post('/auth/login', { username, password });
        console.log('Auth check response:', response);

        const { accessToken, refreshToken } = response.data;
        console.log('Login successful:', { accessToken, refreshToken });
        const tokenExpiry = Date.now() + 15 * 60 * 1000;
        set({
          isAuthenticated: true,
          loading: false,
          token: accessToken,
          refreshToken,
          tokenExpiry,
        });
        Cookies.set('token', accessToken);
        Cookies.set('refreshToken', refreshToken);
        Cookies.set('tokenExpiry', tokenExpiry.toString());
      } catch (error) {
        set({
          isAuthenticated: false,
          loading: false,
          error: 'An error occurred during login. Please try again.',
        });
        throw error;
      }
    },

    logout: () => {
      console.log('Logging out');

      set({
        isAuthenticated: false,
        token: null,
        refreshToken: null,
        tokenExpiry: null,
      });
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      Cookies.remove('tokenExpiry');
      api.post('/auth/logout');
    },
  };
});
