import Arrow from '../../../app/assets/icons/arrowTopRight.svg';
import IconWrapper from './style';

export const ArrowTopRight = ({
  size = '24px',
  color = '#afcbe2',
  onClick,
}: {
  size?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <IconWrapper size={size} onClick={onClick} color={color} cursor="pointer">
      <Arrow />
    </IconWrapper>
  );
};

export default ArrowTopRight;
