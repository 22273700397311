import { Outlet } from 'react-router-dom';
import { useAuthStore } from '../../../pages/auth/store/authStore';
import { useEffect, useState } from 'react';
import Splash from '../../../pages/splash';
import styled from 'styled-components';
import { AppBody, AppMainLayout } from './Style';
import Header from '../../../features/ThreeJsHtmlExperience/Header';

const TransitionContainer = styled.div`
  animation: fadeIn 1s ease-in;
  width: 100%;
`;

const Layout = () => {
  const [showSplash, setShowSplash] = useState<boolean>(
    !sessionStorage.getItem('splashShown'),
    // true,
  );
  const [fadingOut, setFadingOut] = useState<boolean>(false);
  const [showMainContent, setShowMainContent] = useState<boolean>(false);
  const { loading } = useAuthStore();

  useEffect(() => {
    if (showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false);
        setFadingOut(true);
        sessionStorage.setItem('splashShown', 'true');
        setTimeout(() => setShowMainContent(true), 1000); // Delay to match the fadeOut animation
      }, 5000); // Total duration including the translation animation
      return () => clearTimeout(timer);
    } else {
      setShowMainContent(true);
    }
  }, [showSplash]);

  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowHeader(true), 14000);
  }, []);

  return (
    <AppBody>
      {!showSplash && showHeader && <Header />}
      <AppMainLayout>
        {showSplash ? (
          <Splash fadingOut={fadingOut} />
        ) : (
          showMainContent && (
            <TransitionContainer>
              <main>
                <Outlet />
              </main>
            </TransitionContainer>
          )
        )}
      </AppMainLayout>
    </AppBody>
  );
};

export default Layout;
