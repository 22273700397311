import styled from 'styled-components';

const IconWrapper = styled.div`
  display: inline-block;
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
  border: none;
  background: none;
  svg {
    width: 100%;
    height: 100%;
    /* fill: ${({ color }) => color || 'currentColor'}; */
    fill: ${({ fillColor }) => fillColor || ''};
    color: ${({ color }) => color || ''};
    /* cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')}; */
    cursor: ${({ cursor }) => cursor || 'default'};
  }
`;

export default IconWrapper;
