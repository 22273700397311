import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Html, useProgress } from '@react-three/drei';
import Col from '../../components/layout/Col';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const SplashContainer = styled.div<{ $fadingOut: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary);
  animation: ${({ fadingOut }) => (fadingOut ? fadeOut : fadeIn)} 1s ease-in-out;
  color: var(--primary2);
  font-size: 1.4rem;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBar = styled.div`
  width: 300px;
  height: 10px;
  background-color: var(--primary);
  margin-top: 20px;
`;

const ProgressIndicator = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: var(--primary2);
  transition: width 0.3s ease-in-out;
`;

const Big = styled.div`
  font-size: 8.5rem;
  font-weight: bold;
`;

const Splash: React.FC<{ fadingOut: boolean }> = ({ fadingOut }) => {
  const { progress: actualProgress } = useProgress();
  const [simulatedProgress, setSimulatedProgress] = useState(0);

  useEffect(() => {
    const incrementProgress = () => {
      if (simulatedProgress < 100) {
        const randomIncrement = Math.random() * (5 - 1) + 1; // Random increment between 1 and 5
        setSimulatedProgress((prevProgress) =>
          Math.min(prevProgress + randomIncrement, 100),
        );
      }
    };

    const progressInterval = setInterval(incrementProgress, 200); // Increment every 200ms

    if (simulatedProgress >= 100) {
      clearInterval(progressInterval);
    }

    return () => clearInterval(progressInterval);
  }, [simulatedProgress]);

  return (
    <SplashContainer $fadingOut={fadingOut}>
      <Loader>
        <Col>
          Welcome to
          <Big>SOR SEO</Big>
        </Col>
        <div>Loading... {Math.round(simulatedProgress)}%</div>
        <ProgressBar>
          <ProgressIndicator progress={simulatedProgress} />
        </ProgressBar>
      </Loader>
    </SplashContainer>
  );
};

export default Splash;
