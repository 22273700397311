import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --gradient: linear-gradient(70deg, #2c2d2c 0%, #142d34 52%, #2c2d2c 100%);
    --font-family-primary: 'Inter', 'Nunito Sans', sans-serif;

    --primary: #AFCBE2;
    --primary2: #191E22;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  #root {
    position: relative;
  }
  
  
  html, body {
    font-family: var(--font-family-primary);
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.body};
    font-size: 62.5%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3 {
    font-family: 'Inter';
    font-weight: 400;

  }

  .absolute {
    position: absolute;
  }
  
  .top-0 {
    top: 0;
  }

  // Animations
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-in;
  }
`;

export default GlobalStyle;
