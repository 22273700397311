import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from '../components/layout/AppMainLayout';
import PrivateRoute from '../util/routing/PrivateRoute';
import { ThemeProvider } from 'styled-components';
import { lazy, useState, Suspense } from 'react';

import { lightTheme, darkTheme, GlobalStyle } from './theme';

import { useGLTF } from '@react-three/drei';
import OurWork from '../pages/OurWork';
import PortfolioItem from '../pages/OurWork/Portfolio/PortfolioItem';
import { Contact } from '../pages/Contact';
import { ClientLogin } from '../pages/ClientLogin';
import PrivacyPolicy from '../pages/misc/PrivacyPolicy';
import TermsConditions from '../pages/misc/TermsConditions';
import LegalNoticeCookies from '../pages/misc/LegalNoticeCookies';
import AccountBasedMarketing from '../pages/AccountBasedMarketing';

const LoginPage = lazy(() => import('../pages/auth/Login'));
const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
// import About from '../pages/About';

useGLTF.preload('/models/heart.glb');

const App = () => {
  const [theme, setTheme] = useState('dark');
  const isDarkTheme = theme === 'dark';
  // const toggleTheme = () => setTheme(isDarkTheme ? 'dark' : 'light');

  return (
    <Router>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <GlobalStyle />

        <Suspense fallback={''}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path="/" element={<Home />} />
              <Route index path="/:lang" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />

              <Route element={<PrivateRoute />}>
                <Route
                  path="/private"
                  element={<p>this is a private page</p>}
                />
              </Route>

              <Route path="/:lang/about" element={<About />} />
              <Route path="/:lang/a-propos" element={<About />} />

              <Route path="/:lang/nos-realisations" element={<OurWork />} />
              <Route path="/:lang/our-work" element={<OurWork />} />
              <Route path="/:lang/our-work/:slug" element={<PortfolioItem />} />
              <Route
                path="/:lang/nos-realisations/:slug"
                element={<PortfolioItem />}
              />

              <Route path="/:lang/contact" element={<Contact />} />

              <Route path="/:lang/app/login" element={<ClientLogin />} />
              <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/:lang/politique-de-confidentialite"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/:lang/terms-and-conditions"
                element={<TermsConditions />}
              />
              <Route
                path="/:lang/termes-et-conditions"
                element={<TermsConditions />}
              />
              <Route
                path="/:lang/legal-notice-cookies"
                element={<LegalNoticeCookies />}
              />
              <Route
                path="/:lang/mentions-legales-et-cookies"
                element={<LegalNoticeCookies />}
              />
              <Route
                path="/:lang/account-based-marketing"
                element={<AccountBasedMarketing />}
              />
            </Route>
          </Routes>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
