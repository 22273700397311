import styled from 'styled-components';
import React from 'react';

// import { sendPageView } from '../../utils/analytics'
import { useTranslation } from 'react-i18next';
import { SectionTitle, SectionWrapper } from '../About/Style';
import Signature from '../../components/ui/elements/Signature';
import { PortfolioItemOverview } from './PortfolioItemOverview';

export const Layout = styled.div`
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

const OurWork = React.memo(() => {
  const { t } = useTranslation('ourWork');

  const projectsTranslations = t('projects', { returnObjects: true });
  //take "projectsTranslations" and map it to a projects array
  const projects = Object.keys(projectsTranslations).map((key) => ({
    ...projectsTranslations[key],
  }));

  // useEffect(() => {
  //   sendPageView()
  // }, [])

  return (
    <Layout>
      <SectionWrapper width="83%">
        <SectionTitle>
          <a
            href="/"
            title="SOR SEO Agency"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {t('ourWorkOverview.title.intro')}
          </a>
          <Signature>{t('ourWorkOverview.title.unique')}</Signature>
          <br />
          {t('ourWorkOverview.title.middle')}
          <Signature>{t('ourWorkOverview.title.work')}</Signature>
        </SectionTitle>
      </SectionWrapper>
      {projects.map((item, index) => (
        <PortfolioItemOverview data={item} />
      ))}
    </Layout>
  );
});

export default OurWork;
