import { defineStore } from './defineMagicalStore';

const state = {
  menuIsOpen: false,
  isBloodMode: true,
};

const actions = (set) => ({
  toggleMenu: () => set((state) => ({ menuIsOpen: !state.menuIsOpen })),
  toggleBloodMode: () => set((state) => ({ isBloodMode: !state.isBloodMode })),
});

export const useUIStore = defineStore(state, actions);
